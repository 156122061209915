<template>
  <div>
    <!-- https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier -->
    <!-- https://v3.vuejs.org/guide/component-custom-events.html#v-model-arguments -->
    <component :is="selectedStep"
               :settings="settings"
               :totalPrice="totalPrice"
               :totalPriceAdults="totalPriceAdults"
               :totalPriceChildren="totalPriceChildren"
               @change-step="setSelectedStep"
               @prev-step="setSelectedStep"
               v-model:booking="booking">
    </component>
  </div>
</template>

<script>
import BookingStep1 from './BookingStep1.vue';
import BookingStep2 from './BookingStep2.vue';
import BookingStep3 from './BookingStep3.vue';
import axios from "axios";

export default {
  components: {
    BookingStep1,
    BookingStep2,
    BookingStep3
  },
  data() {
    return {
      settings: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        token: process.env.VUE_APP_TOKEN,
      },
      selectedStep: 'BookingStep1',
      booking: {
        id: null,
        numberOfAdults: 1,
        numberOfChildren: 0,
        selectedDate: new Date(),
        selectedTimeSlot: null,
        adultPrice: null,
        childrenPrice: null,
        name: null,
        surname: null,
        phone: null,
        email: null,
        comments: null,
        urlPaycomet: null,
        peopleNumberLimitPerSlotTime: null
      },
    }
  },
  provide() {
    return {
      booking: this.booking
    }
  },
  computed: {
    totalPrice: function () {
      return this.totalPriceAdults + this.totalPriceChildren
    },
    totalPriceAdults: function () {
      return this.booking.numberOfAdults * this.booking.adultPrice
    },
    totalPriceChildren: function () {
      return this.booking.numberOfChildren * this.booking.childrenPrice
    },
  },
  methods: {
    setSelectedStep(step) {
      this.selectedStep = step;
    }
  },
  mounted() {
    let url = this.settings.baseUrl + 'price-list'
    axios
        .get(url,{
          'headers': { 'Authorization': 'Bearer ' + this.settings.token
          }
        })
        .then( (response) => {
          this.booking.adultPrice = response.data.adultPrice
          this.booking.childrenPrice = response.data.childrenPrice
          this.booking.peopleNumberLimitPerSlotTime = response.data.peopleNumberLimitPerSlotTime
        })
        .catch(function (error) {
          //TODO: gestionar error
          console.log(error);
        })
  }
}
</script>