<template>
  <div>
    <a @click="prevStep()" class="btn-return">volver</a>
    <h2 class="titulo-left">RESERVAR</h2>

    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Personas</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ dateString }}</td>
              <td>{{ booking.selectedTimeSlot.label }}</td>
              <td>{{ booking.numberOfAdults }} {{ booking.numberOfAdults === 1?' adulto': 'adultos' }}<br>
                  {{ booking.numberOfChildren }} {{ booking.numberOfChildren === 1?' niño': 'niños' }}</td>
              <td>{{ Number(totalPrice).toLocaleString() }} €</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="name">Nombre *</label>
            <input required="true" type="text" class="form-control" id="name" aria-describedby="nombre" placeholder="Nombre" v-model="name">
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label for="name">Apellidos*</label>
            <input required="true" type="text" class="form-control" id="surname" aria-describedby="surname" placeholder="Apellidos" v-model="surname">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="name">Teléfono *</label>
            <input required="true" type="text" class="form-control" id="phone" aria-describedby="phone" placeholder="Teléfono" v-model="phone">
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label for="name">Correo electrónico *</label>
            <input required="true" type="email" class="form-control" id="email" aria-describedby="surname" placeholder="Correo electrónico" v-model="email">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="name">Comentarios</label>
            <textarea id="comments" class="form-control" cols="30" rows="3" v-model="comments"></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="politica-privacidad" v-model="politicaPrivacidad" >
            <label class="form-check-label" for="politica-privacidad">Acepto <a href="https://www.vegadetordin.com/vegadetordin_POLITICA_PRIVACIDAD.pdf" target="_blank">la política de privacidad</a> *</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="condiciones-reserva" v-model="condicionesReserva">
            <label class="form-check-label" for="condiciones-reserva">Acepto <a href="/WEB_TERMINOS Y CONDICIONES_RESERVAS.pdf" target="_blank">los terminos y condiciones de reserva</a> *</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="comunicaciones-comerciales" v-model="aceptaComunicacionComercial">
            <label class="form-check-label" for="comunicaciones-comerciales">Acepto recibir comunicaciones comerciales y por vía electrónica</label>
          </div>
        </div>
      </div>



      <button class="btn-booking" :disabled="!canSendData()">CONTINUAR</button>
    </form>

  </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {
  props: ['totalPrice','totalPriceAdults','totalPriceChildren', 'settings'],
  emits: ['change-step'],
  inject: ['booking'],
  data() {
    return {
      name: this.booking.name,
      surname: this.booking.surname,
      phone: this.booking.phone,
      email: this.booking.email,
      comments: this.booking.comments,
      politicaPrivacidad: false,
      condicionesReserva: false,
      aceptaComunicacionComercial: false,
    }
  },
  methods: {
    prevStep() {
      this.$emit('change-step','BookingStep1');
    },
    submitForm() {
      const url = this.settings.baseUrl + 'complete-pre-reservation';
      this.booking.name = this.name
      this.booking.surname = this.surname
      this.booking.phone = this.phone
      this.booking.email = this.email
      this.booking.comments = this.comments
      this.booking.aceptaComunicacionComercial = this.aceptaComunicacionComercial

      //validar datos de entrada
      axios.put(url, {
            "id": this.booking.id,
            "name": this.booking.name,
            "surname": this.booking.surname,
            "phone":  this.booking.phone,
            "email":  this.booking.email,
            "comments": this.booking.comments,
            "aceptaComunicacionComercial": this.booking.aceptaComunicacionComercial
          },
          {
            'headers': {
              'Authorization': 'Bearer ' + this.settings.token
            },
          })
          .then( (response) => {
            this.booking.urlPaycomet =  response.data.urlPaycomet
            this.$emit('change-step', 'BookingStep3')
          })
          .catch(function (error) {
            //TODO: gestionar error
            console.log(error);
          })
    },
    canSendData(){
      return this.booking.id && this.name !== '' && this.phone !== '' && this.email !== null && this.politicaPrivacidad && this.condicionesReserva
    }
  },
  computed: {
    dateString: function () {
        return moment(this.booking.selectedDate).format("DD/MM/YYYY")
    }
  }
 }
</script>