<template>
  <div>
    <br>
    <div class="row">
      <div class="col-md-12">
        <p>
          No se ha podido completar la reserva.
        </p>
      </div>
    </div>

  </div>
</template>