<template>
   <h2 class="titulo-left">RESERVAR</h2>

    <h3>Número de personas</h3>

    <table class="table">
      <thead>
      <tr>
        <th></th>
        <th>Precio</th>
        <th style="min-width: 120px;">Cantidad</th>
        <th>Tarifa con visita y degustación</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Adultos mayores de 10 años</td>
        <td>{{ booking.adultPrice }}€</td>
        <td>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="input-group-text btn-number" data-field="number_adults" data-type="minus" v-on:click="decrementNumberAdults" :disabled="booking.numberOfAdults <= 1">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </button>
            </div>

            <input type="text" class="form-control input-number" name="number_adults" v-bind:value="booking.numberOfAdults">

            <div class="input-group-append">
              <button class="input-group-text btn-number" data-field="number_adults" data-type="plus" v-on:click="incrementNumberOfAdults()" :disabled="booking.numberOfAdults + booking.numberOfChildren >= booking.peopleNumberLimitPerSlotTime">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </td>
        <td><span id="total-price-adults">{{ Number(totalPriceAdults).toLocaleString() }}</span>€</td>
      </tr>
      <tr>
        <td>Niños de 3 a 10  años</td>
        <td>{{ Number(booking.childrenPrice).toLocaleString() }}€</td>
        <td>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="input-group-text btn-number" data-field="number_children" data-type="minus" v-on:click="decrementNumberOfChildren()" :disabled="booking.numberOfChildren <= 0">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </button>
            </div>

            <input type="text" class="form-control input-number" name="number_children" v-bind:value="booking.numberOfChildren">

            <div class="input-group-append">
              <button class="input-group-text btn-number" data-field="number_children" data-type="plus" v-on:click="incrementNumberOfChildren()" :disabled="booking.numberOfAdults + booking.numberOfChildren >= booking.peopleNumberLimitPerSlotTime">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </td>
        <td><span id="total-price-children">{{ Number(totalPriceChildren).toLocaleString() }}</span>€</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="3" style="text-align: right"><strong>TOTAL</strong></td>
        <td><span><strong>{{ Number(totalPrice).toLocaleString() }}</strong></span>€</td>
      </tr>
      </tfoot>
    </table>

    <h3>Días disponibles</h3>
    <Loading v-if="isLoadingCalendar"></Loading>
  <DatePicker v-else
      is-expanded
      title-position="left"
      :attributes='attributes'
      :select-attribute='selectAttribute'
      :min-date="new Date()"
      :max-date ="maxDate()"
      :disabled-dates="disabledDates"
      v-model="date"
      @dayclick="dayClicked">
    </DatePicker>

    <h3 style="clear:both">Horas disponibles</h3>
    <Loading v-if="isLoadingTimeSlots"></Loading>
    <ul v-else id="time-slots">
      <li v-for="timeSlot in timeSlots"
          v-bind:key="timeSlot.id"
          @click="timeSlot.available && selectTimeSlot(timeSlot.id)"
          :class="[ timeSlot.available ? 'available' : 'no-available',booking.selectedTimeSlot && booking.selectedTimeSlot.id === timeSlot.id ?'active':'']">
          {{ timeSlot.label }}
      </li>
    </ul>

    <div style="clear: both"></div>

    <p>
      <button @click="nextStep()" type="button" class="btn-booking" :disabled="!canSendData()">CONTINUAR</button>
    </p>

</template>

<script>
import { DatePicker } from 'v-calendar';
import axios from 'axios'
import moment from 'moment'
import Loading from './Loading.vue'

let data = {
  date: new Date(),
  fullDates: [],
  disabledDates: [],
  timeSlots: [],
  isLoadingCalendar: false,
  isLoadingTimeSlots: false,
  selectAttribute: {
    highlight: {
      style: {
        backgroundColor: '#5b8f21',
        width: '100% !important',
        height: '100% !important',
        borderRadius: 'initial !important',
        color: 'pink'
      },
      contentStyle: {
        color: 'white',
        borderRadius: 'initial !important',
        backgroundColor: '#5b8f21',
      },
    },
  }
}

export default {
  props: [ 'totalPrice', 'totalPriceAdults', 'totalPriceChildren', 'settings'],
  inject: ['booking'],
  components: { DatePicker, Loading },
  emits: ['change-step'],
  data() {
    return data
  },
  computed: {
    attributes() {
      let aux = [
        {
          dates: this.fullDates,
          highlight: {
            color: 'red',
            contentClass: 'complete-date',
            fillMode: 'solid'
          }
        }
      ]
      return aux
    }
  },
  methods: {
    maxDate() {
      let date = new Date();
      return date.setMonth(date.getMonth() + 1);
    },
    dayClicked(day) {
      const wrongDate = (element) => {
        let date = Date.parse(element);
        return date === day.date.getTime();
      };
      if(!this.fullDates.some(wrongDate)) {
        this.booking.selectedDate = day.date
      }
    },
    loadCalendar() {
      this.isLoadingCalendar = true;
      const url = this.settings.baseUrl + 'calendar?number-of-adults=' + this.booking.numberOfAdults + '&number-of-children=' + this.booking.numberOfChildren;
      //https://stackoverflow.com/questions/38399050/vue-equivalent-of-settimeout
      axios
          .get(url, {
            'headers': {
              'Authorization': 'Bearer ' + this.settings.token
            }
          })
          .then((response) => {

            this.isLoadingCalendar = false
            const data = response.data
            this.fullDates = Object.values(data.fullDates)
            this.disabledDates = Object.values(data.disabledDates)

          })
          .catch(function (error) {
            //TODO: gestionar error
            console.log(error);
          })
    },
    loadTimeSlots() {
      this.isLoadingTimeSlots = true;
      const date = this.booking.selectedDate;
      //const dateISO8601String = moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
      const dateString = moment(date).format("YYYY-MM-DD");
      const url = this.settings.baseUrl + 'available-time-slots?number-of-adults=' + this.booking.numberOfAdults + '&number-of-children=' + this.booking.numberOfChildren + '&date=' + encodeURIComponent(dateString);

      axios
          .get(url, {
            'headers': {
              'Authorization': 'Bearer ' + this.settings.token
            }
          })
          .then((response) => {
            this.booking.selectedTimeSlot = null

            this.isLoadingTimeSlots = false;
            this.timeSlots = response.data

          })
          .catch((error) => {
            //TODO: gestionar error
            console.log(error);
          })
    },
    selectTimeSlot(timeSlotId) {
      this.booking.selectedTimeSlot = this.timeSlots.find(timeSlot => timeSlot.id === timeSlotId)
    },
    nextStep() {
      const url = this.settings.baseUrl + 'pre-reservation';
      const date = this.booking.selectedDate;
      const dateString = moment(date).format("DD-MM-YYYY");

      //validar datos de entrada

      axios.post(url, {
            "date": dateString,
            "time_slot_id": this.booking.selectedTimeSlot.id,
            "pax": this.booking.numberOfAdults,
            "children_number": this.booking.numberOfChildren,
            "price": this.totalPrice
          },
          {
            'headers': {
              'Authorization': 'Bearer ' + this.settings.token
            },
          })
          .then((response) => {
            this.booking.id = response.data.id
            this.$emit('change-step', 'BookingStep2')
          })
          .catch(function (error) {
            //TODO: gestionar error
            console.log(error);
          })
    },
    canSendData() {
      return this.booking.selectedDate && this.booking.numberOfAdults > 0 && this.booking.numberOfChildren >= 0 && this.booking.selectedTimeSlot;
    },
    incrementNumberOfAdults() {
      const bookingCopy = this.booking;
      bookingCopy.numberOfAdults = this.booking.numberOfAdults + 1
      this.$emit('update:booking', bookingCopy)
    },
    incrementNumberOfChildren() {
      const bookingCopy = this.booking;
      bookingCopy.numberOfChildren = this.booking.numberOfChildren + 1
      this.$emit('update:booking', bookingCopy)
    },
    decrementNumberAdults() {
      const bookingCopy = this.booking;
      bookingCopy.numberOfAdults = this.booking.numberOfAdults - 1
      this.$emit('update:booking', bookingCopy)
    },
    decrementNumberOfChildren() {
      const bookingCopy = this.booking;
      bookingCopy.numberOfChildren = this.booking.numberOfChildren - 1
      this.$emit('update:booking', bookingCopy)
    }
  },
  watch: {
    'booking.numberOfAdults': function () {
      this.loadCalendar();
      this.loadTimeSlots();
    },
    'booking.numberOfChildren': function () {
      this.loadCalendar();
      this.loadTimeSlots();
    },
    'booking.selectedDate': function () {
      this.loadTimeSlots();
    }
  },
  mounted() {
    this.loadCalendar();
    this.loadTimeSlots();
  }
}

</script>

<style>
.vc-day-content{
    background-color: #83c226;
    width: 100% !important;
    height: 100% !important;
    border-radius: initial !important;
  }

 .vc-day-content:hover,
 .vc-day-content:focus{
   background: #83c226;
 }

 .vc-day .is-disabled,
  .vc-day .is-disabled:hover{
    background-color: transparent !important;
    cursor: auto;
    pointer-events: none;
  }

  .vc-day-content.complete-date,
  .vc-day-content.complete-date:hover,
  .vc-day-content.complete-date:focus{
    background-color: #e53e3e !important;
    cursor: auto;
    width: 100% !important;
    height: 100% !important;
    pointer-events: none;
    border-radius: initial !important;
  }

  #time-slots{
    padding: 0;
    display: block;
  }

  #time-slots li{
    float: left;
    margin: 5px 20px 10px 0;
    padding: 10px;
    list-style: none;
  }

  li.no-available{
    background-color: #e53e3e;
    color: white;
  }

  li.available{
    background-color: #83c226;
    cursor: pointer;
  }

  li.available:hover,
  li.active{
    background-color: #5b8f21;
    color: white;

  }
</style>
