import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import BookingStep4 from "./components/BookingStep4";
import TheBooking from "./components/TheBooking";
import KO from "./components/KO";

const router = createRouter(
    {
        history: createWebHistory(),
        routes: [
            { path: '/', component: TheBooking },
            { path: '/booking-step-4', name: 'booking-step-4', component: BookingStep4 },
            { path: '/ko', name: 'ko', component: KO },
        ]
    }
);

const app = createApp(App)
app.use(router)
app.mount('#app')

