<template>
  <div>
    <a @click="prevStep()" class="btn-return">volver</a>
    <h2 class="titulo-left">RESERVAR</h2>

    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Personas</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ dateString }}</td>
              <td>{{ booking.selectedTimeSlot.label }}</td>
              <td>{{ booking.numberOfAdults }} {{ booking.numberOfAdults === 1?' adulto': 'adultos' }} <br>
                  {{booking.numberOfChildren }} {{ booking.numberOfChildren === 1?' niño': 'niños' }}</td>
              <td>{{ Number(totalPrice).toLocaleString() }} €</td>
            </tr>
          <tr>
            <td colspan="4">{{ booking.name }} {{ booking.surname }} </td>
          </tr>
            <tr>
              <td colspan="4">{{ booking.email }} {{ booking.phone }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <iframe title="titulo"
                :src="booking.urlPaycomet"
                sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms"
                frameborder="0" marginheight="0" marginwidth="0" scrolling="no" style="border: 0px solid #000000; padding: 0px; margin: 0px"></iframe>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ['booking','totalPrice','totalPriceAdults','totalPriceChildren'],
  emits: ['change-step'],
  methods: {
    prevStep() {
      this.$emit('change-step','BookingStep2');
    }
  },
  computed: {
    dateString: function () {
      return moment(this.booking.selectedDate).format("DD/MM/YYYY")
    }
  }
 }
</script>