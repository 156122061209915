<template>
  <div>
    <br>
    <div class="row">
      <div class="col-md-12">
        <p>
          Su reserva se ha realizado con éxito. Le hemos enviado un correo electrónico a <strong>{{ booking.email}}</strong> con la información de la reserva.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
          <tr>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Personas</th>
            <th>Importe</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ dateString}}</td>
            <td>{{ booking.timeLabel }}</td>
            <td>{{ booking.numberOfAdults }} {{ booking.numberOfAdults === 1?' adulto': 'adultos' }} <br>
              {{booking.numberOfChildren }} {{ booking.numberOfChildren === 1?' niño': 'niños' }}</td>
            <td>{{ Number(booking.price).toLocaleString() }} €</td>
          </tr>

          <tr>
            <td colspan="4">{{ booking.name }} {{ booking.surname }} </td>
          </tr>
          <tr>
            <td colspan="4">{{ booking.email }} {{ booking.phone }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      settings: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        token: process.env.VUE_APP_TOKEN,
      },
      booking: {},
    }
  },
  computed: {
    dateString: function () {
      return moment(this.booking.date).format("DD/MM/YYYY")
    }
  },
  mounted() {
    console.log(process.env.FOO)
    let reservationId = this.$route.query.order
    let url = this.settings.baseUrl + 'reservations/' + reservationId
    axios
        .get(url,{
          'headers': { 'Authorization': 'Bearer ' + this.settings.token
          }
        })
        .then( (response) => {
          console.log(response.data)
          this.booking = response.data
        })
        .catch(function (error) {
          //TODO: gestionar error
          console.log(error);
        })
  }
}
</script>